<template>
	<div class="mainTem">
		<!-- 顶部提示导航 -->
		<!-- <div style="padding: 0 20px;">
      <top-bar :isFullStyle="true"></top-bar>
    </div> -->
		<el-container class="StorageCon">
			<el-header>
				<div class="SellerTopCon" style="display: flex;justify-content: space-between; width: 100%;">
					<div style="width: 530px; ">
						<div class="" style="display: flex;">
							<div class="titleNavLogo">
								<a href="javascript:void(0)">
									<img src="../../assets/logo_en.png" width="150" height="auto"  />
								</a>
							</div>
							
							<div class="titleNavTit">
								<!-- <router-link :to="{ name: 'WSkuInfo' }">{{ $t('Storage.common.title') }}</router-link> -->
								<!-- <el-popover placement="top" width="210" trigger="hover">
									<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">
										<img src="../../assets/images/wechat_kf/wechat_hytx_wms.jpg" width="200" height="200" />
										<div>互易仓(微信公众号)</div>
										<div>微信扫一扫关注</div>
									</div>
									<a href="javascript:void(0)" slot="reference">
										<div>
											{{ $t('Storage.common.title') }}
										</div>
									</a>
								</el-popover> -->
								<a href="https://www.hytxlogistics.com/warehouse"  target="_blank">
									<div>
										互易海外仓
									</div>
								</a>
							</div>
							<div class="titleNavTit">
								<!-- <el-popover placement="top" width="210" trigger="hover">
									<div>
										<img src="../../assets/images/wechat_kf/wechat_kf.jpg" width="200" height="200" />
										<div style="text-align: center; padding-bottom: 10px; font-weight: bold;">微信扫一扫添加</div>
										<div>手机号：+86 15972134233</div>
										<div>微信号：L245727336</div>
									</div>
									<a href="javascript:void(0)" slot="reference">
										<div>
											客服微信
										</div>
									</a>
								</el-popover> -->
								<a href="https://www.hytxlogistics.com/" target="_blank">互易物流</a>
							</div>
						</div>
					</div>

					<div class="" style="width: 400px; color:#fff; display: flex;">
						
						<div class="topHeaderRi" style="width: 200px;">
							
							<el-dropdown :show-timeout="0">
								<div class="el-dropdown-link">
									<div class="nameLink">
										<span>
											{{ $t('topBar.language') }}: &nbsp;
										</span>
										<span v-if="'en' == $i18n.locale">{{ $t('topBar.English') }}</span>
										<span v-else>{{ $t('topBar.Chinese') }}</span>
										<i class="el-icon-arrow-down el-icon--right"></i>
									</div>
								</div>
								<el-dropdown-menu slot="dropdown" class="topbarDropdown">
									
									<a href="javascript:void(0)" @click="switchLang('cn')">
										<el-dropdown-item>{{ $t('topBar.Chinese') }}</el-dropdown-item>
									</a>
									<a href="javascript:void(0)" @click="switchLang('en')">
										<el-dropdown-item>{{ $t('topBar.English') }}</el-dropdown-item>
									</a>
								</el-dropdown-menu>
							</el-dropdown>
						</div>

						<div class="topHeaderRi" style="width: 200px;">
							<el-dropdown :show-timeout="0">
								<div class="el-dropdown-link">
									<span class="nameLink" v-if="$store.getters.getUserInfo">{{ $store.getters.getUserInfo.mobile||$store.getters.getUserInfo.account }}</span>
									<i class="el-icon-arrow-down el-icon--right"></i>
								</div>
								<el-dropdown-menu slot="dropdown" class="topbarDropdown">
									<!-- <router-link :to="{ name: 'forgotPassword' }">
										<el-dropdown-item>修改密码</el-dropdown-item>
									</router-link> -->
									<a href="javascript:void(0)" @click="openModifyPassword()">
										<el-dropdown-item>{{ $t('i18nn_dae8d92a749abc4e') }}</el-dropdown-item>
									</a>
									<a href="javascript:void(0)" @click="loginOut()">
										<el-dropdown-item>{{ $t('Storage.common.Logout') }}</el-dropdown-item>
									</a>
									<!-- <router-link :to="{name:'helpDoc'}" target="_blank">
										<el-dropdown-item>{{$t('i18nn_54153ccc0c756e9f')}}</el-dropdown-item>
									</router-link> -->
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>

				
			</el-header>

			<el-container :style="{ height: $store.state.frameConHeight + 'px' }">
				<el-aside width="185px">
					<el-menu :default-active="activeIndexSub" class="sellerSubNav" :router="true" @open="handleOpen" @close="handleClose">
						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								
								{{ $t('Storage.nav.product') }}
							</span>
							<el-menu-item :route="{ name: 'WSkuInventories' }" index="WSkuInventories">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.SKU_inventories') }}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WSkuInfo' }" index="WSkuInfo">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.SKU_information') }}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WSkuRecord' }" index="WSkuRecord">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.SKU_water') }}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WSkuSplit' }" index="WSkuSplit">
								<i class="el-icon-document"></i>
								<span slot="title">SKU拆分</span>
							</el-menu-item>
						</el-menu-item-group> -->
						
						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">{{$t('i18nn_ce764b74be1b9a90')}}</span>
							<el-menu-item :route="{ name: 'workOrderList' }" index="workOrderList">
								<i class="el-icon-document"></i>
								<span slot="title">{{$t('i18nn_2f7a4906f66bffd1')}}</span>
							</el-menu-item>
						</el-menu-item-group> -->
						
						
						
						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">
							
								{{ $t('Storage.nav.prediction') }}
							</span>

							<el-menu-item :route="{ name: 'InWarehouse' }" index="InWarehouse">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.My_packing_list') }}</span>
							</el-menu-item>

						
						</el-menu-item-group> -->

						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">{{ $t('Storage.nav.shipments') }}</span>
							<el-menu-item :route="{ name: 'WarehouseShipments' }" index="WarehouseShipments">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.My_invoice') }}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WarehouseDropShipping' }" index="WarehouseDropShipping">
								<i class="el-icon-document"></i>
								<span slot="title">{{ $t('Storage.nav.A_undertakes_to') }}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WarehouseExchangeReturn' }" index="WarehouseExchangeReturn">
								<i class="el-icon-document"></i>
								<span slot="title">退货换标</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'PriterOrderDetList' }" index="PriterOrderDetList">
								<i class="el-icon-document"></i>
								<span slot="title">打单明细</span>
							</el-menu-item>
							
						</el-menu-item-group> -->
						
						<el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								快递面单
							</span>
							<el-menu-item :route="{ name: 'ExpressSheetList' }" index="ExpressSheetList">
								<i class="el-icon-document"></i>
								<span slot="title">快递面单</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'ExpressSheetDetList' }" index="ExpressSheetDetList">
								<i class="el-icon-document"></i>
								<span slot="title">快递面单明细</span>
							</el-menu-item>
						</el-menu-item-group>
						<el-menu-item :route="{ name: 'LgsTrackQuery' }" index="LgsTrackQuery">
							<i class="el-icon-document"></i>
							<span slot="title">物流轨迹查询</span>
						</el-menu-item>
						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								第三方
							</span>
							<el-menu-item :route="{ name: 'ThirdOrder' }" index="ThirdOrder">
								<i class="el-icon-document"></i>
								<span slot="title">第三方订单</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'amazonUserList' }" index="amazonUserList">
								<i class="el-icon-document"></i>
								<span slot="title">Amazon{{$t('i18nn_0698cce1a67a1ff6')}}</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'amazonOrder' }" index="amazonOrder">
								<i class="el-icon-document"></i>
								<span slot="title">Amazon{{$t('i18nn_37181c74559db182')}}</span>
							</el-menu-item>
						
						</el-menu-item-group> -->

						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">{{$t('i18nn_4de574b0302f8cf5')}}</span>
							<el-menu-item  :route="{ name: 'ExceptionalRecord' }" index="ExceptionalRecord">
								<i class="el-icon-document"></i>
								<span slot="title">{{$t('i18nn_b79ec1aa21d476b6')}}</span>
							</el-menu-item>
						</el-menu-item-group> -->

						
						<!-- <el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								
								{{ $t('Storage.nav.report') }}
							</span>
							
							<el-menu-item :route="{ name: 'outBill' }" index="outBill">
								<i class="el-icon-document"></i>
								<span slot="title">出库报表</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'InventoryStatisticsSkuGroup' }" index="InventoryStatisticsSkuGroup">
								<i class="el-icon-document"></i>
								<span slot="title">{{$t('i18nn_350662278f221eee')}}</span>
							</el-menu-item>
              <el-menu-item :route="{ name: 'InventoryStatistics' }" index="InventoryStatistics">
              	<i class="el-icon-document"></i>
              	<span slot="title">{{$t('i18nn_851c121d787993a2')}}</span>
              </el-menu-item>
							
							<el-menu-item :route="{ name: 'WarehouseFeeCollect' }" index="WarehouseFeeCollect">
								<i class="el-icon-document"></i>
								<span slot="title">仓租费用汇总</span>
							</el-menu-item>
							<el-menu-item :route="{ name: 'WarehouseFee' }" index="WarehouseFee">
								<i class="el-icon-document"></i>
								<span slot="title">仓租费用明细</span>
							</el-menu-item>
							
						</el-menu-item-group> -->
						
						<el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								增值服务管理
							</span>
							
							<el-menu-item :route="{ name: 'workOrderList' }" index="workOrderList">
								<i class="el-icon-document"></i>
								<span slot="title">增值服务</span>
							</el-menu-item>
							
						
						</el-menu-item-group>
						
						<el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								售后管理
							</span>
							
							<el-menu-item :route="{ name: 'QARecList' }" index="QARecList">
								<i class="el-icon-document"></i>
								<span slot="title">售后记录</span>
							</el-menu-item>
							
							<el-menu-item :route="{ name: 'ValueAddServiceList' }" index="ValueAddServiceList">
								<i class="el-icon-document"></i>
								<span slot="title">增值服务记录</span>
							</el-menu-item>
							
						
						</el-menu-item-group>
						
						
						<el-menu-item-group>
							<span slot="title" class="subNavTitle3">
								账户
							</span>
							
							<el-menu-item :route="{ name: 'accountInfo' }" index="accountInfo">
								<i class="el-icon-document"></i>
								<span slot="title">账户信息</span>
							</el-menu-item>
							<!-- <el-menu-item :route="{ name: 'RechargeRecord' }" index="RechargeRecord">
								<i class="el-icon-document"></i>
								<span slot="title">充值记录</span>
							</el-menu-item> -->
							<!-- <el-menu-item :route="{ name: 'expenseRecord' }" index="expenseRecord">
								<i class="el-icon-document"></i>
								<span slot="title">消费记录</span>
							</el-menu-item> -->
							
						
						</el-menu-item-group>
						
						
						
						
						<el-menu-item-group>
							<span slot="title" class="subNavTitle3">{{$t('i18nn_ec153a16f143e276')}}</span>
							<el-menu-item :route="{ name: 'agentUser' }" index="agentUser">
								<i class="el-icon-document"></i>
								<span slot="title">{{$t('i18nn_f77f473d27f48bdc')}}</span>
							</el-menu-item>
						</el-menu-item-group>
					</el-menu>
				</el-aside>
				
				<el-main :style="{ padding: '0px' }"><router-view></router-view></el-main>
			</el-container>
			
		</el-container>
		<dialogModifyPassword :openTime="modifyPassOpenTime"></dialogModifyPassword>
	</div>
</template>

<script>
// import topBar from "../header/Topbar.vue";
// import mainSearch from '../header/MainSearch.vue'
// import mainNav from '../header/MainNav.vue'
// import footerMain from '../footer/FooterMain.vue'
// import AmazonAuthModal from '@/components/Amazon/AmazonAuthModal.vue';
import dialogModifyPassword from '@/components/StorageCenter/components/dialogModifyPassword.vue';
export default {
	// name: "SellerCenterFrame",
	components: { dialogModifyPassword },
	
	//meta信息seo用
	metaInfo() {
		return {
			title: this.$t('meta.main')
		};
	},
	watch: {
		$route(to, from) {
			this.activeIndexSub = this.$route.name;
		}
	},
	data() {
		return {
			// activeLang: localStorage.lang || 'cn',
			activeIndex: this.$route.matched && this.$route.matched[1] ? this.$route.matched[1].name : this.$route.name,

			activeName: 'second',
			activeIndexSub: this.$route.name,
			showDetailData: {},
			
			isShowAuthModal: false,
			
			modifyPassOpenTime:"",

			// userId: !!this.$route.params.userId && '0' != this.$route.params.userId ? this.$route.params.userId : this.$store.getters.getUserInfo.id,
			// enterpriseId: !!this.$route.params.enterpriseId && '0' != this.$route.params.enterpriseId ? this.$route.params.enterpriseId : this.$store.getters.getUserInfo.enterpriseId,
			// shopId: !!this.$route.params.shopId && '0' != this.$route.params.shopId ? this.$route.params.shopId : this.$store.getters.getUserInfo.shopId,

			// UserInfo:this.$store.getters.getUserInfo
		};
	},
	// beforeRouteEnter (to, from, next) {
	//   console.log('beforeRouteEnter');
	//   // this.getShopData(()=>{
	//   //   next(vm => {
	//   //     // 通过 `vm` 访问组件实例
	//   //     console.log('beforeRouteEnter',vm);
	//   //     // vm.getShopData();
	//   //   })
	//   // });

	// },
	created() {
		console.log('created', this.userId, this.enterpriseId, this.shopId);
		// if(!this.userId){
		//   this.$alert("用户未登录，请联系管理员：userId未知。", this.$t('tips.tipsTitle'), {
		//     type: 'warning',
		//     callback: action => {
		//       this.$router.push({name:'login'})
		//     }
		//   });
		//   return;
		// }
		// if(!this.enterpriseId){
		//   this.$alert("厂家未认证或审核未通过，请联系管理员：enterpriseId未知", this.$t('tips.tipsTitle'), {
		//     type: 'warning',
		//     callback: action => {
		//       this.$router.push({name:'UserAuthentication'})
		//     }
		//   });
		//   return;
		// }
		// if(!this.shopId){
		//   this.$alert("厂家未认证或审核未通过，请联系管理员：shopId未知", this.$t('tips.tipsTitle'), {
		//     type: 'warning',
		//     callback: action => {
		//       this.$router.push({name:'UserAuthentication'})
		//     }
		//   });
		//   return;
		// }
		// if(null==this.enterpriseId || ''==this.enterpriseId || 'null'==this.enterpriseId){
		//   console.log("null==this.enterpriseId || ''==this.enterpriseId");

		//   if('2'===this.$store.getters.getUserInfo.isParnter){
		//     this.$alert('请从‘合伙人中心-我的推荐’模块进入2', '未知商户', {
		//       type:'warning',
		//       //confirmButtonText: '确定',
		//       callback: action => {
		//         this.$router.push({ name: 'SellerManagement' });
		//       }
		//     });
		//   } else {
		//     this.$alert('立即去登录2', '未知商户', {
		//       type:'warning',
		//       //confirmButtonText: '确定',
		//       callback: action => {
		//         this.$router.push({ name: 'login' });
		//       }
		//     });
		//   }

		// }
	},
	////编译挂载前
	mounted() {
		console.log('SellerCenterFrame.vue created');
		// console.log(this);
		this.$parent.mNavActiveIndex = '8';
		// console.log('SellerCenterFrame created route',this.$route);
		// if(this.$route.matched && this.$route.matched[1]){
		//   this.activeIndex = this.$route.matched[1].name;
		//       // console.log('activeIndex',this.activeIndex);
		// }
		// console.log(this.UserInfo);
		// this.init();
		// this.getShopData();
	},

	methods: {
		//跳转
		toPageName(pageName) {
			this.$router.push({ name: pageName });
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
			console.log('this.activeIndex', this.activeIndex);
			// if(this.$route.matched && this.$route.matched[1]){
			//   this.activeIndex = this.$route.matched[1].path;
			//       // console.log('activeIndex',this.activeIndex);
			// }
		},
		//打开修改密码
		openModifyPassword(){
			this.modifyPassOpenTime = new Date().getTime();
		},
		//打开授权弹窗
		// openAuthModal(){
		// 	// let routeData = this.$router.resolve({
		// 	//    name: "AmazonAuth",
		// 	// });
		// 	// window.open(routeData.href, '_top');
			
		// 	// this.isShowAuthModal = true;
			
		// 	this.$nextTick(()=>{
		// 		this.$refs.AmazonAuthModal.open();
		// 	})
		// },
		//登出
		// loginOut(){
		//   console.log("loginOut");
		//   this.$store.dispatch('USER_SIGNOUT');
		//   // console.log(this);
		//   // window.reload();
		//   console.log(this.$router);
		//   console.log(this.$route);
		//   //登出
		//   this.$http.post(this.$urlConfig.HyLoginOut,{}).then(response => {
		//     console.log('登出', response);
		//   }).catch(err => {
		//     console.error(err);
		//   });
		//   if('home'==this.$route.name){
		//     this.$router.go(0);
		//     // window.location.reload();
		//   } else {
		//     this.$router.replace({name:'home'});
		//     // window.location.reload();
		//   }

		//   // this.$router.go(0);

		//   // this.userInfo = this.$store.getters.getUserInfo;
		//   // this.role = this.$store.getters.getRole;
		//   // this.$location.reload();
		// },
		//切换语言
		switchLang(lang) {
			// let lang = 'en';
			// if ('cn' === this.$i18n.locale) {
			// 	lang = 'en';
			// } else {
			// 	lang = 'cn';
			// }
			this.$i18n.locale = lang;
			// this.activeLang = lang;
			// localStorage.setItem("lang",lang);
			this.$store.dispatch('UPDATE_LANG', lang);

			// this.$message.success("切换语言成功，建议重新刷新浏览器。");
			this.$message.success(this.$t('topBar.switchLangSuccess'));
			// window.location.reload();
			// this.$confirm(this.$t('topBar.switchLangSuccess'), this.$t('tips.tipsTitle'), {
			// 	// //confirmButtonText: '确定',
			// 	// //cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(() => {
			// 		window.location.reload();
			// 	})
			// 	.catch(() => {});
			// console.log(localStorage.lang);
		},
		// testClick() {
		//   console.log(11);
		// },
		//登出
		loginOut() {
			console.log('loginOut');
			this.$store.dispatch('USER_SIGNOUT');
			console.log('dispatch1', this.$store.getters.getUserInfo);
			if (this.$store.getters.getUserInfo && this.$store.getters.getUserInfo.id) {
				this.$store.dispatch('USER_SIGNOUT');
				console.log('dispatch2', this.$store.getters.getUserInfo);
			}
			// console.log(this);
			// window.reload();
			console.log(this.$router);
			console.log(this.$route);
			//登出
			this.$http
				.post(this.$urlConfig.HyLoginOut, {})
				.then(response => {
					console.log('登出', response);
				})
				.catch(err => {
					console.error(err);
				});
			// return;
			// if ('WarehouseSku' == this.$route.name) {
			//   this.$router.go(0);
			//   // window.location.reload();
			// } else {
			this.$router.replace({
				name: 'login'
			});
			// window.location.reload();
			// }
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/**/
.StorageCon {
	.el-aside {
		background-color: #2d333e;
		border-right: 1px solid #e6e6e6;
		.el-menu {
			border-right: 0;
			background-color: #2d333e;
			.el-menu-item-group__title {
				// padding-left: 0 !important;
			}

			.el-menu-item {
				font-size: 12px;
				color: #fff;
				height: 40px;
				line-height: 40px;
				span {
					// display: inline-block;

					// padding-left: 20px;
					// margin-left: 15px;
				}
				i {
					color: #fff;
				}
				&:hover,
				&:focus {
					background-color: #2577e4;
					color: #fff;
					i {
						color: #fff;
					}
				}
			}
			.el-menu-item.is-active {
				background-color: #2577e4;
				// color: #fff;
				// i {
				//   color: #fff;
				// }
			}

			// .el-menu-item:hover, .el-menu-item:focus {
			//   color: #2D333E;
			// }
		}
	}
	.el-header {
		padding: 0;
		.sellerMainNav {
			.el-menu-item {
				color: #aeafb0 !important;
				&:hover,
				&:focus {
					// background-color: #414957!important;
					color: #fff !important;
				}
			}
			.is-active {
				background-color: #414957 !important;
				border-bottom-color: #414957 !important;
				color: #fff !important;
			}
			.titleNav {
				text-align: center;
				width: 184px;
				font-size: 16px;
				color: #fff !important;
			}
			.userNav {
				float: right;
				.userNavHead {
					border-radius: 30px;
					overflow: hidden;
				}
				.userNavHeadLogo {
					max-width: 400px;
					overflow: hidden;
				}
			}
		}
	}
	.sellerSubNav {
		min-height: 600px;
	}
}

/*左边侧导航*/
.subNavTitle3 {
	display: block;
	// margin-top: 10px;
	// margin-left: 20px;
	// text-align: center;
	color: #ccc;
	font-size: 12px;
	line-height: 12px;
	padding: 0;
	padding-top: 5px;
	font-weight: 400;
}
// .el-menu-vertical-demo:not(.el-menu--collapse) {
//   width: 200px;
//   min-height: 400px;
// }
// .subNavTitle {
//   display: block;
//   margin-top: 10px;
//   margin-right: 20px;
//   text-align: center;
//   color: #fff;
//   font-size: 16px;
// }
.SellerTopCon {
	// background-color: #405D79;
	background: #29435E;
	display: flex;
	width: 100%;
	height: 60px;
	.titleNavLogo {
		width: 184px;
		// height: 60px;
		margin-top: 14px;
		// padding-left: 10px;
		text-align: center;
		a {
			width: 150px;
			height: 100%;
			// background: url(../../assets/logo2.png) no-repeat center center;
			// background-size: 150px auto;

			display: inline-block;
			text-decoration: none;
			text-align: center;
		}
	}
	.titleNavTit {
		height: 60px;
		width: 150px;
		text-align: center;
		a {
			line-height: 60px;
			font-size: 16px;
			color: #fff;
			display: block;
			text-decoration: none;
		}
	}
}

// .topNavCon {
//   .topNavCon_left {

//     width: 200px;
//     margin-left: -100%;
//     float: left;
//   }
//   .topNavCon_center {
//       width: 100%;
//       float: left;
//       .topHeadNav {
//         margin-left: 200px;
//         margin-right: 150px;
//       }
//   }
.topNavCon_right {
	width: 150px;
	// margin-left: -150px;
	// float: right;
}
// }
.topHeaderRi {
	// text-align: right;
	// height: 60px;
	// padding-right: 20px;
	overflow: hidden;
	color: #fff;
	min-width: 120px;
	background-color: #29435e;
	margin-left: 1px;
	text-align: center;
	.el-dropdown {
		width: 100%;
		color: #fff;
		cursor: pointer;
		.el-dropdown-link {
			display: block;
			width: 100%;
			height: 100%;
			.nameLink {
				display: inline-block;
				vertical-align: middle;
				max-width: 150px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 55px;
			}
			.el-icon-arrow-down {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}
/*顶部下拉*/
.topbarDropdown.el-dropdown-menu {
	// padding: 5px 0;
	// margin-top: 0px;
	background-color: #274c79;
	top: 48px !important;
	width: 150px;
	a {
		text-decoration: none;
		color: #fff;
		.el-dropdown-menu__item {
			color: #fff;
			&:hover {
				background-color: #206ce0;
			}
			// font-size: 12px;
			// line-height: 30px;
			// padding: 0 20px;
		}
	}
	&[x-placement^='bottom'] .popper__arrow::after {
		border-bottom-color: #274c79;
	}
	&[x-placement^='bottom'] .popper__arrow::after {
		border-bottom-color: #274c79;
	}
}
</style>
